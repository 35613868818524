import React from 'react';

//import NewFaceView from '../NewFaceView';
//import AboutUsHeader from './AboutUsHeader';
import { Helmet } from 'react-helmet-async';
import vission from '../../assets/images/vis.jpg';
import mission from '../../assets/images/mission.jpg';
export default function VisionMission() {
  return (
    <div className='sec vission-mission'>
    <Helmet>
      <title>Vision & Mission - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Vision & Mission - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Vision & Mission - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/about/vision-mission" />
    </Helmet>
       {/*} <AboutUsHeader />
        <NewFaceView />*/}
        <div className="container-fluid ">
        <h1 className="text-primary" style={{textAlign:"center"}}>About Us</h1>
            <h1 className="mb-2" style={{textAlign:"center"}}>Vision</h1>
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s" style={{textAlign:"center"}}>
                        <div className="h-100 position-relative" style={{width: "100%"}} >
                            <img src={vission} className="animated img-fluid w-75 rounded" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                      <p>  Vidyajyoti envisions an integral formation,  which includes human, intellectual, spiritual and contextual-pastoral formation of the students. The training enables the learners to be “men and women for others”, who would selflessly serve the people, especially the weaker sections of society irrespective of caste, colour or creed.</p>
                    </div>
                </div>
            </div>
            <h1 className="mb-4" style={{textAlign:"center"}}>Mission</h1>
            <div className="container py-5">
                <div className="row g-5">
                <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                        <p >“Initiatives and activities of Vidyajyoti… are intended to promote the values of Christian tradition (from the experiences of the Context), and in particular, values of our time most in need – the dignity of human person, the protection of creation, the fundamental dimension of faith, openness to the challenges of the world of culture and sciences, ecumenical dialogue and relations with other religions – in conformity with the principles of the evangelizing mission of the Church”  (Statutes of Vidyajyoti, Art. 1)</p>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s" style={{textAlign:"center"}}>
                        <div className="h-100 position-relative" style={{width: "100%"}} >
                            <img src={mission} className=" animated img-fluid w-75 rounded" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

import React, { useEffect } from 'react';
import {Row, Col} from 'reactstrap';
import imgvjgp from '../../assets/images/groupphoto24.png'

export default function PrincipalMessage () {
  
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

    return (
      <div className='container sec p-2'>
        <Row >
            <Col  md={5} style={{textAlign:"center", justifyContent:"center"}}>
           <img  src={imgvjgp} alt='' />     
            </Col>
            </Row>
            <Row>
            <Col >
                <div>
                    <p style={{color:"black",  textAlign:"justify"}}>
                    Vidyajyoti is not just an institution. It is an ATTITUDE of theologizing in the Context.  It shapes the way of life.  Vidyajyoti is a  name of its own significance.  
                    Vidyajyoti means Light of Knowledge and the motto of VJ is taken from the Katha Upanishad, 5:15, “tam eva bhantam anubuti sarvam” meaning “Everything reflects the one who alone shines”.  Guided by that spirit Vidyajyoti engages very seriously in Contextual Theology and thereby striving to make Life-realities, struggles and sufferings of people our starting point for Theologizing. 
                    </p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
            <br/>
            <h3 className='text-primary'> UNIQUENESS</h3>
                    <p style={{color:"black", textAlign:"justify"}}>
                    Vidyajyoti Theologate is an out of the box set up.  
                    <ul clasNames="checklist " style={{listStyle:"none"}}>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>
            We don’t have a huge Baratmandapam … but a place where new initiatives and liberative thoughts could emerge and transform the society at large. 
    </li>	
    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>
    We do not have large grounds, but huge network of engaging with Catholic and Non-Catholic Institutions, Government and Non-governmental and with Jawaharlal Nehru, St. Stephen, Indian Social Institute and Delhi Universities. 
    </li>
    </ul>
                    </p>
                    <h3 className='text-primary'> FIELDWORK: </h3>
                    <p style={{color:"black", lineHeight:"35px", textAlign:"justify"}}>
                    <ul clasNames="checklist " style={{listStyle:"none"}}>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>
                Keeping our original vision in mind, we reach out to Slums, Rickshaw pullers and children of sex workers, Prison ministry and Animate Migrants, refugees and youth in forming to become good leaders and eco-care consciousness warriors. 

                    </li>   
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>
                        These ministries are done in integration with our theological learning. We have Pastoral Reflection session every month in each ministry group guided by our professors. 
            </li>
            </ul>
                    </p>
                    <h3 className='text-primary'> FLAGSHIP PROGRAMMES: </h3>
                    <p style={{color:"black", lineHeight:"35px", textAlign:"justify"}}>
                    <ul clasNames="checklist " style={{listStyle:"none"}}>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>	Vidyajyoti Journal of Theological Reflections (VJTR):  It is a Research and Scientific Theological Journal theologizing on contemporary issue and ecclesial challenges.  </li>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>	Distance Education Programme in Theology (DEPTh):  It caters to lay people from India, Malaysia, US and Mauritius. About 400 plus are currently enrolled and about 200 plus have completed the course in the last few years.</li>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i> VJTODAY: Our student editors and writers bring out their theological reflections, dreams and vision for a transformed society through triannual publications. </li>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>	AVE is a cute daily Meditation booklet for every month, written by our own students. It reaches out to nearly 5000 plus communities and families. </li>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>	VIDIS is another creative contribution to build Christian Muslim relations </li>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>	SALAAM is a quarterly journal published by Islamic Studies Association.</li>
</ul>

                    </p>
                    <p style={{color:"black", lineHeight:"35px", textAlign:"right"}}><h4 className='text-primary'>Prof. Dr. Fr. Rajakumar Joseph SJ</h4>
Vidyajyoti College of Theology,<br/>
Institute for Religious Studies,<br/>
Delhi – 54. 
</p>
                    </Col>
        </Row>
      </div>
  )
}

import React from 'react';
import { visitingFacultyList } from '../../ProData';

export default function TeamContent() {    

  return (
    <div>
        <div className="container-fluid team">
            <div className="container py-5">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                    <h1 className="mb-3 text-dark-main">Meet our Visiting Faculty</h1>
                </div>
                <div className="row g-4 ">
        {visitingFacultyList.map((item, index)=>
                    <div key={index} className="col-lg-4 col-xl-3 wow fadeIn d-flex justify-content-center" data-wow-delay=".3s" >
           <div className="rounded team-item " style={{height:"400px", width:"280px"}}>
           <div className="team-content ">
               <div className="team-img-icon">
                   <div className="team-img rounded-circle">
                   <img src={item.avatarSrc} className="img-fluid w-100 rounded-circle" style={{height:"200px", maxHeight:"200px"}} alt="Avatar"/>
                   </div>
                   <div className="team-name text-center py-3">
                       <h4 className="">{item.name}</h4>
                       <p className="m-0">{item.subject}</p>
                   </div>
               </div>
           </div>
       </div>
       </div>

        )}
        </div>
            </div>
        </div>
    </div>
  )
}

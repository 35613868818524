import React from 'react';
import NewFaceView from './NewFaceView';
import CaroselMultiView from './CaroselMultiView';
import {Row, Col, Button  } from "reactstrap";
import {Helmet} from "react-helmet-async";
import VidyaJyotiBanner from '../VidyaJyotiBanner';
//import '../css/style.css';
import PhotoGallery from '../../pages/gallery/PhotoGallery';
import TimelineVJ from '../../pages/TimeLineVJ/TimelineVJ';
//import AboutUsHomepageView from '../about/AboutUsHomepageView';
import TheologicalInsights from './TheologicalInsights';
import Services from '../publications/Services';
import ContactContent from '../contact/ContactContent';
import FieldWorkContent from '../fieldwork/FieldWorkContent';

import { Link } from 'react-router-dom'
//import UpcomingEvents from './upcomingEvents/UpcomingEvents';
import UpcomingMain from './upcomingEvents/UpcomingMain';

export default function HomePage() {
  return (
    <div className='home-sec'>
    <Helmet>
      <title>Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/" />
    </Helmet>

    <div className="App" >
      <Row className='caroselrow pb-3' style={{marginLeft:"0px", marginRight:"0px"}}>
        <Col  md={9} >
        <CaroselMultiView />
        <NewFaceView/>
        </Col>
        <Col  md={3} >
         <TheologicalInsights />
        </Col>
      </Row>
      <div style={{marginLeft:"10px", marginRight:"0px"}}>
      <UpcomingMain />
      <VidyaJyotiBanner />
      </div>
      
      <h1 className='text-dark-main'>VJ TOP STORIES</h1>
      <div className='card  mb-5' style={{marginLeft:"10px", marginRight:"10px"}}>
        <div style={{height:"500px", overflow:"scroll"}}>
      <TimelineVJ />  
      </div>
      <Button variant="primary" type="submit" ><Link to="/about/vjtopstories"> <a href="#" >Read More...</a></Link></Button>
      </div>
      
      

      {/*<div className="container-fluid project py-2 my-2">
            <div>
                <div className="text-center mx-auto wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                    <h5 className="text-primary">VIDYAJYOTI COLLEGE</h5>
                    <h1 className='text-dark-main'>Principal's Desk</h1>
                </div>
      <AboutUsHomepageView />
      <Button className='text-white' style={{marginTop: "-7.5%", marginLeft:"15%"}} variant="primary" type="submit" ><Link to="/about/principalmessage"><a href="#" className='text-white' >Read More...</a></Link></Button>
      </div>
      </div>*/}
      <PhotoGallery task={"Home"}/>

      <FieldWorkContent />
      <Services />

      <ContactContent />
    </div>
    </div>
  )
}

import React from 'react';
import {Row, Col} from 'reactstrap';
import imgvjgp from '../../assets/images/VJCollegeGp.png'
  export default function AboutUsHomepageView() {

    return (
      <div className=' p-2'>
        <Row >
            <Col  style={{textAlign:"center", justifyContent:"center"}}>
            <img className=''  src={imgvjgp} alt='' height="350px" width="400px"/>
            </Col>
        </Row>
      </div>
    )
  
}

import React from 'react';
import logo from '../../assets/logo/VJ-College-logo.png';
import './CustomSpinner.css';


export default function CustomSpinner() {
  return (
    <div className='image-overlay'>
    <section className="preloaderidntica">
    <div className="custom-loader"></div> 
    <span><img src={logo} alt="" style={{width: "140px", height:"140px"}}/></span>
    </section>
    </div>
  )
}

import React, {useState, useEffect} from 'react';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
//import axios from "axios";
//import { useSnackbar } from 'notistack';
import {Helmet} from "react-helmet-async";
//import onlineadmissionimg from '../../lib/img/online admission.jpg'
import LanguageForm from './LanguageForm';
import InstitutionForm from './InstitutionForm';
//import DocSuperiorLetter from './DocSuperiorLetter';
//import DocMarkList from './DocMarkList';
//import DocPhotoProof from './DocPhotoProof';
//import DocBPHCert from './DocBPHCert';
import { phoneCode , coursesOffered} from '../../ProData';
//import CustomSpinner from '../../hooks/customerSpinner/CustomSpinner';
//import { event } from 'jquery';

//const config = require('../../config.json');
//const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function PreviewScreen(props) {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
    const {optedCourse, profileDetails, recommenLetterFile, markListFile, imageFile, certProofFile, viewOnly, 
      emailCodeEntered, setEmailCodeEntered, handleSubmit, handleEmailValidation } = props;

  return (
    <div className='container' style={{color:"black"}}>
    <Helmet>
      <title>Application Form Preview- Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Application Form - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Application Form - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/admission/application-form" />
    </Helmet>

       
        <h1 className="mb-3" style={{textAlign:"center", padding:"10px"}}>Preview Of Your Application</h1>
      

      <div className="course-title">
      <h5 className=" mb-4" >{optedCourse.courseName}</h5>
         
      </div>
          
    

      
        <div className="p-2 rounded contact-form">
        <Card>
      <Card.Body>
        <Form style={{fontSize:"18px"}}>
        <Row>
           
          </Row> 
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label><h5>Student Name</h5></Form.Label>
                <Form.Control required type="text" placeholder="Name (As per the Certificate)" value={profileDetails.applicantName}
                                 disabled={viewOnly}
                                    />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label><h5>Father Name</h5></Form.Label>
                <Form.Control required type="text" value={profileDetails.fatherName}
                    disabled={viewOnly} placeholder='Father name...'
                       />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label><h5>Email</h5></Form.Label>
                <Form.Control required type="email" value={profileDetails.emailID}
                    disabled={viewOnly} placeholder='Email address...'
                      />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Label><h5>Mobile Number</h5></Form.Label>
            <Row>
              <Col sm={4} className="mb-3">
              <Form.Group id="areanumber">
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                type="select" name="courseName"
                      value={profileDetails.areaCode} 
                      disabled={viewOnly}
                        >
                      {phoneCode.map(item => {
                    return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                  })}
                  </Form.Select>
                 </Form.Group>
            </Col>
             <Col sm={8} className="mb-3">
              <Form.Group id="mnumber">
                <Form.Control required type="number" placeholder="Mobile number" 
                                disabled={viewOnly}
                                value={profileDetails.mobileNumber} 
                />
              </Form.Group>
            </Col>
            </Row>
            </Col>    
          </Row>
          <Row className="align-items-center">
            <Col md={4} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label><h5>Date Of Birth</h5></Form.Label>
                <Form.Control type="date" value={profileDetails.dateOfBirth}
                                  disabled={viewOnly}
                                 
               />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="gender">
                <Form.Label><h5>Gender</h5></Form.Label> <br />
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.gender} 
                                    disabled={viewOnly}
                                   
                                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group id="nationality">
                <Form.Label><h5>Nationality</h5></Form.Label>
                <Form.Control required type="text" placeholder="Nationality" value={profileDetails.nationality}
                                  disabled={viewOnly}
                  />
              </Form.Group>
            </Col>

          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="maritalstatus">
                <Form.Label><h5>Place of Birth</h5></Form.Label> <br />
                <Form.Control type="text" size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.placeOfBirth}
                placeholder='Place of Birth...' disabled={viewOnly}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="district">
                <Form.Label><h5>District</h5></Form.Label>
                <Form.Control required type="text" value={profileDetails.birthDistrict}
                    disabled={viewOnly} placeholder='District...'
                  />
              </Form.Group>
            </Col>

            <Col md={4} className="mb-3">
              <Form.Group id="state">
                <Form.Label><h5>State</h5></Form.Label>
                <Form.Control required type="text" value={profileDetails.birthState}
                  disabled={viewOnly} placeholder='State...'
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="religion">
                <Form.Label><h5>Religious Status</h5></Form.Label> <br />
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.religiousStatus} 
                  disabled={viewOnly}
                >
                  <option value="Scholastic">Scholastic</option>
                  <option value="Brothers">Brothers</option>
                  <option value="Sisters">Sisters</option>
                  <option value="Laity">Laity</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="denomination">
                <Form.Label><h5>Parish & Diocese of Origin</h5></Form.Label>
                <Form.Control required type="text" value={profileDetails.parishDiocese}
                  disabled={viewOnly} placeholder='Parish & Diocese details...'
                 
                />
              </Form.Group>
            </Col>
            {profileDetails.religiousStatus!=="Laity"&&
            <Col sm={4} className="mb-3">

              <Form.Group id="diocese">
                <Form.Label><h5>Congregation and Province</h5></Form.Label>
                <Form.Control required type="text" value={profileDetails.congregationProvince}
                  disabled={viewOnly} placeholder='Enter Congregation & Province details...'
                  
                />
              </Form.Group>
            </Col>}
          </Row>
          <Card><h5> Local Address</h5>
          <Row>
          <Col sm={12} className="mb-3">
              <Form.Group id="address">
                <Form.Label></Form.Label>
                <Form.Control required type="text" value={profileDetails.localAddress}
                    disabled={viewOnly} placeholder='Enter local address...'
                  />
              </Form.Group>
              </Col>
          </Row>
          <Row>
          <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label><h5>State</h5></Form.Label>
                <Form.Control required type="text" placeholder="Enter your State" value={profileDetails.localState}
                    disabled={viewOnly}
                  />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="localPincode">
                <Form.Label><h5>Pincode</h5></Form.Label>
                <Form.Control required type="tel" placeholder="Postal Pincode" value={profileDetails.localPincode}
                    disabled={viewOnly}
                />
              </Form.Group>
            </Col>  
            <Col sm={4}>
              <Form.Group id="localContact">
                <Form.Label><h5>Contact No (Personal)</h5></Form.Label>
                <Form.Control required type="tel" placeholder="Contact No" value={profileDetails.localContact}
                    disabled={viewOnly}
                />
              </Form.Group>
            </Col>            
          </Row>
          </Card>
          <Card> <h5>Permanent Address</h5>
          <Row>
          <Col sm={12} className="mb-3">
              <Form.Group id="address">
                <Form.Label></Form.Label>
                <Form.Control required type="text" value={profileDetails.perAddress}
                    disabled={viewOnly} placeholder='Enter permanent address....'
                  />
              </Form.Group>
              </Col>
          </Row>
          <Row>
          <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label><h5>State</h5></Form.Label>
                <Form.Control required type="text" placeholder="Enter your State" value={profileDetails.perState}
                                  disabled={viewOnly}
                  />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="zip">
                <Form.Label><h5>Pincode</h5></Form.Label>
                <Form.Control required type="tel" placeholder="Postal Pincode" value={profileDetails.perPincode}
                                  disabled={viewOnly}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="zip">
                <Form.Label><h5>Contact No (Gaurdian)</h5></Form.Label>
                <Form.Control required type="tel" placeholder="Contact No" value={profileDetails.perContactNo}
                                  disabled={viewOnly}
                                 
                />
              </Form.Group>
            </Col>            
          </Row>
          </Card>
</Form>
</Card.Body>
        </Card>
            <InstitutionForm formType={"View"} form={profileDetails}  />
            <LanguageForm formType={"View"} form={profileDetails} />
            <Card className='p-2'>
              <h5 >ATTACHMENTS </h5>
            <Row >
            {recommenLetterFile && <Col  className='pb-2' sm={3}>
                   <h5>Recommendation Letter of Your Superior </h5> 
        <img className='img-fluid' src={URL.createObjectURL(recommenLetterFile)} />
                </Col>}
                {markListFile && <Col className='pb-2' sm={3}>
               <h5>Attached Philosophy Mark List </h5> 
                <img className='img-fluid' src={URL.createObjectURL(markListFile)} />
                    
                </Col>}
          
                {imageFile && <Col className='pb-2' sm={3}> <h5 >Profile Image </h5>
                <img className='img-fluid' src={URL.createObjectURL(imageFile)} />
                </Col>}
                {certProofFile && <Col className='pb-2' sm={3}>  <h5 > College Certificate </h5>
                <img className='img-fluid' src={URL.createObjectURL(certProofFile)} />
                </Col>}
            </Row>
          
    <Card border="light" className="container  shadow-sm mb-4 d-flex mx-auto">
      <Card.Body className="text-center justify-content-center mx-auto">     
       
        <Form className="text-center justify-content-center ">
        <div className="m-3 " >
           <Row className="justify-content-center text-center">
        <Form.Group id="otp">
                <Form.Label><h5>Enter the One Time Password </h5></Form.Label>
                <Form.Control required type="text" placeholder="Enter OTP" 
                value={emailCodeEntered} onChange={(event)=>{setEmailCodeEntered(event.target.value)}}/>
              </Form.Group>
        </Row>
        <div className="mt-3 text-center">
            <Button variant='primary m-3' type="submit" ><a href="admission/application-form" >Go Back</a></Button>
            <Button variant="primary" type="submit" onClick={(event)=>{handleEmailValidation(event)}}>Resend OTP</Button>

          </div>
        </div>
       

        </Form>
        </Card.Body>
     </Card>
            <Row className='p-5 mb-2'>
            <Button className="btn bg-dark-main text-white py-3 px-3"  style={{textAlign:"center"}} type="submit" 
            onClick={event=>handleSubmit(event)}>Submit</Button>
            </Row>
            </Card>
            </div>

    
    </div>
  )
}

import React from 'react';
import TeamContent from './TeamContent';
import {Helmet} from "react-helmet-async";

export default function TeamMain() {
  return (
    <div>
    <Helmet>
      <title>Faculty - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Faculty - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Faculty - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/about/residentialFaculty" />
    </Helmet>
        <TeamContent />
    </div>
  )
}

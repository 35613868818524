import React from 'react';
import {Helmet} from "react-helmet-async";


export default function DiplomaContent() {
  return (
 
    <div className='sec'>
    <Helmet>
      <title>Diploma in Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Diploma in Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Diploma in Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/academics/diploma" />
    </Helmet>
    <div className='container'>
        <h1 className="mb-3" style={{textAlign:"center"}}>Diploma in THEOLOGY</h1>
        <p style={{color:"black", textAlign:'justify'}}>Students who do not wish to do the B.Th. degree course may do a three year Diploma course. They do not have to do the Dissertation and some other aspects of the B.Th. which the Principal will indicate. There will be a final examination of an hour.</p>
    </div>
    </div>
  )
}

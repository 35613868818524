import React from 'react';
import {Helmet} from "react-helmet-async";

export default function CertificateContent() {
  return (
    <div>
         <div className='sec'>
    <Helmet>
      <title>Certificate in Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Certificate in Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Certificate in Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/academics/Certificate" />
    </Helmet>
    <div className='container'>
        <h1 className="mb-3" style={{textAlign:"center"}}>Certificate in THEOLOGY</h1>
        <p style={{color:"black", textAlign:'justify'}}>Students take appropriate courses for one or two years (First and Second Year Certificates). A Total of about 30 credits per year is required. Courses are chosen under the guidance of a staff guide selected by the Principal.</p>
    </div>
    </div>
    </div>
  )
}

import React from 'react';
import {Helmet} from "react-helmet-async";


export default function DoctoralContent() {
  return (
 
    <div className='sec'>
    <Helmet>
      <title>Doctoral of Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Doctoral of Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Doctoral of Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/academics/doctoral" />
    </Helmet>

    <div className='container'>
        <h1 className="mb-3" style={{textAlign:"center"}}>DOCTOR OF THEOLOGY(D.Th.)</h1>
        <p style={{color:"black", textAlign:'justify'}}>The third cycle, which leads to the Doctorate (D.Th.) applies fully the methods of scientific research, particularly through the writing of a thesis which must contribute to the progress of theological science. Some special courses of lectures and seminar participation are prescribed. The Doctorate thesis, examined by three examiners, will carry a weightage of 80% and the Defence 20%.</p>
    </div>
    </div>
  )
}

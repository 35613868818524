import React from 'react';
import Services from './Services';
import PublicationHeader from './PublicationHeader';
import {Helmet} from "react-helmet-async";

export default function ServiceMain() {
  return (
    <div>
    <Helmet>
      <title>Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/publications" />
    </Helmet>

        <PublicationHeader />
        <Services />

    </div>
  )
}

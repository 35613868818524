import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PDFViewer from '../../components/PDFViewer';
import axios from 'axios';
import {Helmet} from "react-helmet-async";
import Preloader from '../../components/Preloader/Preloader';
import { useSnackbar } from 'notistack';

const config = require('../../config.json');

export default function Tatavaviveka() {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedItem, setSeletedItem] = useState('');
  const [pdfFile, setPdfFile] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResult, setSearchResult] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchPublicationList();
  }, [])

  const fetchPublicationList =async()=>{
    try{
        setLoaded(false);
    const fetchOption="PubCategory";
    const fetchKey="Active";
    const fetchCategory="Tattvaviveka";
    await axios.get(`${config.api.invokeURL}/dashboard/publications?fetchOption=${fetchOption}&fetchKey=${fetchKey}&fetchCategory=${fetchCategory}`,{
        headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
        //console.log("Response : ", response);
        setSearchResult(response.data.Items);
        enqueueSnackbar(response.data.Items.length + " - active records found in VJToday",{variant:'success'})
        setLoaded(true);
        window.scrollTo(0,0); 
    }).catch((error)=>{
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}) 
        setLoaded(true);
        window.scrollTo(0,0); 
        setLoaded(true);
    })
}catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
    setLoaded(true);
    window.scrollTo(0,0); 
    setLoaded(true);
}
setLoaded(true);
}

  const toggle = () => {
      setCollapsed(!collapsed);
  }

  const handlePDFViewer=async(event, item)=>{
    event.preventDefault();
    //console.log("Item : ", item);
    setLoaded(false);
    setSeletedItem(item);
    try{
      const fetchFile=item.id+".pdf";
      const bucketName='docs.vidyajyoticollege.in/pdfs/vjtoday';
      await axios.get(`${config.api.invokeURL}/presigned/static?fileName=${fetchFile}&bucketName=${bucketName}`,{
          headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
          }}, 
      ).then(async(response)=>{
          console.log("Response : ", response);
          setPdfFile(response.data.downloadURL);
          setCollapsed(!collapsed);
          setLoaded(true);
      }).catch((error)=>{
        setLoaded(true);
          //console.log("Error1 : ", error);
      })  
      
    }catch(error){
      setLoaded(true);
      //console.log("Error2 : ", error);
  }
  }

  return (
  <>
    <Helmet>
      <title>Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/publications" />
    </Helmet>

    <Preloader show={loaded ? false : true} />
   <Modal  fullscreen isOpen={!collapsed} style={{top:'0%'}}>
    <ModalHeader
      close={<button className='btn bg-white' onClick={toggle}>X</button>}
      toggle={toggle}>
        {selectedItem.issue}
        </ModalHeader>
    <ModalBody>
      <PDFViewer pdfFile={pdfFile}/>
    </ModalBody>
    </Modal>
    <div className='container'>
      <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
          <h5 className="text-primary">Tattvaviveka</h5>
      </div>
      <div className="row g-5 justify-content-center">
        {searchResult.map((item, index)=>
            <div key={index} className="col-lg-6 col-xl-3 wow fadeIn" data-wow-delay=".3s">
              <div className="card blog-icon btn btn-secondary px-3 rounded-pill" onClick={event=>handlePDFViewer(event, item)}> 
              <a href="" className="btn text-primary">{item.issue}</a></div>
            </div>)}
        </div>
    </div>
  </>
  )
}

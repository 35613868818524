import React, { useState } from 'react';
import axios from 'axios';
import {Row, Col, Card, Button} from 'reactstrap';
import {Helmet} from "react-helmet-async";
import { useSnackbar } from 'notistack';

import CustomSpinner from '../../components/customerSpinner/CustomSpinner';

const config = require('../../config.json');

export default function ApplicationStatus() {
  const [applicationNo, setApplicationNo]= useState('');
  const [dateOfBirth, setDateOfBirth]=useState('');
  const [loaded, setLoaded] = useState(true);
  const [statusMsg, setStatusMsg]=useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleStatusCheck=async()=>{
    try{
        setLoaded(false);
        const clientID="VJCollege";
        await axios.get(`${config.api.invokeURL}/form/registration?clientID=${clientID}&applicationNo=${applicationNo}&dateOfBirth=${dateOfBirth}`,{
            headers: {
             // Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
         // console.log(response);
          setStatusMsg(response.data);
          if(response.status===200){
            enqueueSnackbar("Current Application Status : " + response.data,{variant:'success'}); 
          }else{
            enqueueSnackbar(response.data,{variant:'success'}); 
          }
            //console.log(response);
            setLoaded(true);
        }).catch((error)=>{
            //console.log(error);
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});       
            setLoaded(true);
            //setDispMsg(error.message);
        })
    
    }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});       
        setLoaded(true);
    }
}

  return (
    <>
    <Helmet>
      <title>Application Status - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Application Status - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Application Status - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/admission/application-status" />
    </Helmet>

        {!loaded &&  <CustomSpinner />}
    <div className='container' style={{display:'flex', justifyContent:'center', padding:'50px'}}>
<Row>
    <Card  >
    <div >
                <div className="info-box mb-3" >
                  
                  <h3 className='text-center text-primary'>Registration Status</h3>
                  <div className="row">
                  <h5>Status: {statusMsg}</h5>
                <Col className='mt-3' xs={12} lg={6}>
                  Application Number:
                  <input type="text" name="applicationNo" className="form-control" id="applicationNo" placeholder="Application No" required
                  value={applicationNo} onChange={(event)=>{setApplicationNo(event.target.value)}}/>
                </Col>
                <Col className='mt-3'  xs={12} lg={6}>
                  Date of Birth:
                  <input type="date" className="form-control" name="dob" id="dob" placeholder="Date of Birth" required
                  value={dateOfBirth} onChange={(event)=>{setDateOfBirth(event.target.value)}}/>
                </Col>
              </div> </div>
              <div className="mt-3 text-center">
            <Button className="btn bg-dark-main text-white" type="submit" onClick={(event)=>{handleStatusCheck(event)}} >Submit</Button>
          </div>
              </div>
    </Card>
</Row>
    

    </div>
    </>
  )
}

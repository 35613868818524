import React from 'react'
import collegeLogo from '../../assets/logo/VJ-College-logo.png';
import mottoLogo from '../../assets/images/moto.jpeg';

export default function AboutUsContent() {
  return (
    <div>
        <div id="aboutStartContent" className="container-fluid py-5 my-5">
        <h5 className="text-primary" style={{textAlign:"center"}}>About Us</h5>

        <h1 className="mb-4" style={{textAlign:"center"}}>COLLEGE CREST</h1>
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                        <div className="h-100 position-relative" style={{width: "600px"}} >
                            <img src={collegeLogo} className="fade-in-image img-fluid w-75 rounded" alt=""/>
                            {/*<div className="position-absolute w-75" style={{top: "30%", left: "30%"}}>
                                <img src="img/theology-pic.png" className="img-fluid w-100 rounded" alt=""/>
                            </div>*/}
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                       {/*} <h5 className="text-primary">About Us</h5>
                        <h1 className="mb-4">Contextualization of Theology (CT)</h1>*/}
                        <p style={{color:"black", textAlign:"justify", lineHeight:"50px"}}>College’s Crest which carries Vidyajyoti’s motto is profound in its theological meaning combining g interdisciplinary and inter-religious dimensions. Cross in it, shaped by an eye and a flame, symbolizes Christian wisdom. The star in the eye represents Mary, the Institution’s Patroness. The crest is based on the College Motto, tam eva bhantam anubhati sarvam (Kath. Up. 5:15) - “Everything shines after Him as He shines.”  The suggestion is that God (the flame) is the only source of light that shines in human wisdom (the eye) as it searches for the meaning of reality and dispels the grip of darkness that envelops the world.</p>
                       {/*} <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed efficitur quis purus ut interdum. Pellentesque aliquam dolor eget urna ultricies tincidunt. Nam volutpat libero sit amet leo cursus, ac viverra eros tristique. Morbi quis quam mi. Cras vel gravida eros. Proin scelerisque quam nec elementum viverra. Suspendisse viverra hendrerit diam in tempus. Etiam gravida justo nec erat vestibulum, et malesuada augue laoreet.</p>
                        <p className="mb-4">Pellentesque aliquam dolor eget urna ultricies tincidunt. Nam volutpat libero sit amet leo cursus, ac viverra eros tristique. Morbi quis quam mi. Cras vel gravida eros. Proin scelerisque quam nec elementum viverra. Suspendisse viverra hendrerit diam in tempus.</p>*/}
                        {/*<a href="" className="btn btn-secondary rounded-pill px-5 py-3 text-white">More Details</a>*/}
                    </div>
                    {/*<p style={{textAlign:"justify", lineHeight:"35px"}}>An experience of the negativities of life and a contact and commitment to a specific community of the marginalized ones of society help us theologize better. In its intentionality, theology aims at a transformation of individuals and of structures. Hence with the help of the human and revealed sciences we follow a method where life and the inherited faith meet and challenge each other. We reflect on our way of living our faith in our situation so as to trigger transformation through cognitive, attitudinal, and behavioral changes. Preferentially we concentrate our fieldwork on the “poor”, the marginalized and the downtrodden. In the massive rejection of the marginalized who form the majority of our people we touch the deepest sin of the modern world. In this way emphasis is given to the spirituality and the human growth of the persons in training.</p>*/}
                </div>
               {/*} <div style={{textAlign:"justify", lineHeight:"35px"}}>
                    <p>
                <h5 className="text-primary">The central themes for the three years are as follows:</h5>
                    <ol>
                        <li>Theme 1: God within History and the Response of Faiths</li>
                        <li>Theme 2: God in Jesus Christ, Church and Sacraments</li>
                        <li>Theme 3: Christians in the World</li>
                    </ol>
                    </p>
                    </div>*/}
            </div>

            <h1 className="mb-4" style={{textAlign:"center"}}>Our MOTTO</h1>
            <div className="container py-5">
                <div className="row g-5">
                <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                        <p style={{color:"black", textAlign:"center", lineHeight:"50px"}}>tam eva bhantam anubhati sarvam </p>
                        <p style={{color:"black", textAlign:"center", lineHeight:"50px"}}>“Everything reflects the one who alone shines.”</p>
                        <p style={{color:"black", textAlign:"center", lineHeight:"50px"}}>Derived from the Katha Upanishad 5:15</p>
                        <p style={{color:"black", textAlign:"center", lineHeight:"50px"}}>It implies,  Vidyajyoti is ever committed to seek that eternal Light that is Life, to reflect on it and to let it shine in new human insight and expression.</p>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                        <div className="h-100 position-relative" style={{width: "600px"}} >
                            <img src={mottoLogo} className="fade-in-image img-fluid w-75 rounded" alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className="mb-4" style={{textAlign:"center"}}>UNIQUENESS OF VIDYAJYOTI’S  THEOLOGY</h1>
            <div className="container py-5">
                <div className="row g-5">
                <p style={{color:"black", textAlign:"justify", lineHeight:"40px"}}>The Vidyajyoti B.Th. Syllabus is envisaged in three tiers corresponding to the three years of the study of Theology at Vidyajyoti.  Each of the three tiers has a specific focus spelt out by the theme. Each tear is further divided into “units” made up of various courses (of one or more credits each), and they develop a sub-theme or a further specification of the year’s theme. The purpose behind the new syllabus is to enable the student to do theology in an integrated and holistic manner. The Indian/South Asian dimension is integral to all the aspects of the syllabus. </p>
                </div>
            </div>            

            <h1 className="mb-4" style={{textAlign:"center"}}>CONTEXTUAL-INCLUSIVE METHODOLOGY</h1>
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                        <div className="h-100 position-relative">
                            <img src="img/theology.jpeg" className="bounce animated img-fluid w-75 rounded" alt="" style={{marginBottom: "35%"}}/>
                            <div className="position-absolute w-75" style={{top: "20%", left: "20%"}}>
                                <img src="img/theology-pic.png" className="bounce animated img-fluid w-100 rounded" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                       {/*} <h5 className="text-primary">About Us</h5>
                        <h1 className="mb-4">Contextualization of Theology (CT)</h1>*/}
                        <p style={{color:"black", textAlign:"justify", lineHeight:"40px"}}>The method of starting from the South Asian subaltern realities and groups and opening out to the macro realities of the world characterizes the process. Human sciences, including philosophy, are important. Apostolic/Fieldwork involvement is integral to the theological process.  An experience of the negativities of life and a contact and commitment to a specific community of the marginalized ones of society help us theologize better. </p>
                        <p style={{color:"black", textAlign:"justify", lineHeight:"40px"}}>In its intentionality, theology aims at a transformation of individuals and of structures.  Hence with the help of the human and revealed sciences we follow a method where life and the inherited faith meet and challenge each other. We reflect on our way of living our faith in our situation so as to trigger transformation through cognitive, attitudinal, and behavioural changes. Preferentially we concentrate our fieldwork on the “poor”, the marginalized and the downtrodden. In the massive rejection of the marginalized who form the majority of our people we touch the deepest sin of the modern world. In this way emphasis is given to the spirituality and the human growth of the persons in training.</p>

                       {/*} <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed efficitur quis purus ut interdum. Pellentesque aliquam dolor eget urna ultricies tincidunt. Nam volutpat libero sit amet leo cursus, ac viverra eros tristique. Morbi quis quam mi. Cras vel gravida eros. Proin scelerisque quam nec elementum viverra. Suspendisse viverra hendrerit diam in tempus. Etiam gravida justo nec erat vestibulum, et malesuada augue laoreet.</p>
                        <p className="mb-4">Pellentesque aliquam dolor eget urna ultricies tincidunt. Nam volutpat libero sit amet leo cursus, ac viverra eros tristique. Morbi quis quam mi. Cras vel gravida eros. Proin scelerisque quam nec elementum viverra. Suspendisse viverra hendrerit diam in tempus.</p>*/}
                        {/*<a href="" className="btn btn-secondary rounded-pill px-5 py-3 text-white">More Details</a>*/}
                    </div>
                    {/*<p style={{textAlign:"justify", lineHeight:"35px"}}>In its intentionality, theology aims at a transformation of individuals and of structures.  Hence with the help of the human and revealed sciences we follow a method where life and the inherited faith meet and challenge each other. We reflect on our way of living our faith in our situation so as to trigger transformation through cognitive, attitudinal, and behavioural changes. Preferentially we concentrate our fieldwork on the “poor”, the marginalized and the downtrodden. In the massive rejection of the marginalized who form the majority of our people we touch the deepest sin of the modern world. In this way emphasis is given to the spirituality and the human growth of the persons in training.</p>*/}
                </div>
                <div style={{color:"black", textAlign:"justify", lineHeight:"35px"}}>
                    <p>
                <h5 className="text-primary">The central themes for the three years:</h5>
                    <ol>
                        <li>Theme 1: God within History and the Response of Faiths</li>
                        <li>Theme 2: God in Jesus Christ, Church and Sacraments</li>
                        <li>Theme 3: Christians in the World</li>
                    </ol>
                    </p>
                </div>
            </div>


            {/*<h1 className="mb-4" style={{textAlign:"center"}}>Contextualization of Theology (CT)</h1>
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                        <div className="h-100 position-relative">
                            <img src="img/theology.jpeg" className="img-fluid w-75 rounded" alt="" style={{marginBottom: "35%"}}/>
                            <div className="position-absolute w-75" style={{top: "30%", left: "30%"}}>
                                <img src="img/theology-pic.png" className="img-fluid w-100 rounded" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                       {/*} <h5 className="text-primary">About Us</h5>
                        <h1 className="mb-4">Contextualization of Theology (CT)</h1>*/}
                       {/*} <p style={{textAlign:"justify", lineHeight:"35px"}}>The Vidyajyoti B.Th. Syllabus is envisaged in three tiers corresponding to the three years of the study of Theology at Vidyajyoti. (The ‘Fourth Year’ required for the priesthood is to be taken care of by the respective Provinces / Congregations / Dioceses after the B.Th. Final Comprehensive Exams). Each of the three tiers has a specific focus spelt out by the theme. Each tier is further divided into “units” made up of various courses (of one or more credits each), and they develop a sub-theme or a further specification of the year’s theme. The purpose behind the syllabus is to enable the student to do theology in an integrated and holistic manner. The Indian / South Asian dimension is integral to all the aspects of the syllabus. The method of starting from the South Asian subaltern realities and groups and opening out to the macro realities of the world characterizes the process. Human sciences, including philosophy, are important. Apostolic / Fieldwork involvement is integral to the theological process.</p>
                       {/*} <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed efficitur quis purus ut interdum. Pellentesque aliquam dolor eget urna ultricies tincidunt. Nam volutpat libero sit amet leo cursus, ac viverra eros tristique. Morbi quis quam mi. Cras vel gravida eros. Proin scelerisque quam nec elementum viverra. Suspendisse viverra hendrerit diam in tempus. Etiam gravida justo nec erat vestibulum, et malesuada augue laoreet.</p>
                        <p className="mb-4">Pellentesque aliquam dolor eget urna ultricies tincidunt. Nam volutpat libero sit amet leo cursus, ac viverra eros tristique. Morbi quis quam mi. Cras vel gravida eros. Proin scelerisque quam nec elementum viverra. Suspendisse viverra hendrerit diam in tempus.</p>*/}
                        {/*<a href="" className="btn btn-secondary rounded-pill px-5 py-3 text-white">More Details</a>*/}
                   {/*} </div>
                    <p style={{textAlign:"justify", lineHeight:"35px"}}>An experience of the negativities of life and a contact and commitment to a specific community of the marginalized ones of society help us theologize better. In its intentionality, theology aims at a transformation of individuals and of structures. Hence with the help of the human and revealed sciences we follow a method where life and the inherited faith meet and challenge each other. We reflect on our way of living our faith in our situation so as to trigger transformation through cognitive, attitudinal, and behavioral changes. Preferentially we concentrate our fieldwork on the “poor”, the marginalized and the downtrodden. In the massive rejection of the marginalized who form the majority of our people we touch the deepest sin of the modern world. In this way emphasis is given to the spirituality and the human growth of the persons in training.</p>
                </div>
                <div style={{textAlign:"justify", lineHeight:"35px"}}>
                    <p>
                <h5 className="text-primary">The central themes for the three years are as follows:</h5>
                    <ol>
                        <li>Theme 1: God within History and the Response of Faiths</li>
                        <li>Theme 2: God in Jesus Christ, Church and Sacraments</li>
                        <li>Theme 3: Christians in the World</li>
                    </ol>
                    </p>
                </div>
            </div>*/}
        </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
//import { VJTodayList } from '../../ProData';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PDFViewer from '../../components/PDFViewer';
import axios from 'axios';
//import PublicationHeader from './PublicationHeader';
import {Helmet} from "react-helmet-async";
import Preloader from '../../components/Preloader/Preloader';
import { useSnackbar } from 'notistack';
const config = require('../../config.json');

export default function VJToday() {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedItem, setSeletedItem] = useState('');
  const [pdfFile, setPdfFile] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResult, setSearchResult] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchPublicationList();
  }, [])

  const fetchPublicationList =async()=>{
    try{
        setLoaded(false);
    const fetchOption="PubCategory";
    const fetchKey="Active";
    const fetchCategory="VJ Today";
    await axios.get(`${config.api.invokeURL}/dashboard/publications?fetchOption=${fetchOption}&fetchKey=${fetchKey}&fetchCategory=${fetchCategory}`,{
        headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
        //console.log("Response : ", response);
        const tempRes=response.data.Items;
        tempRes.sort((a, b) => new Date(b.regDate) - new Date(a.regDate));
        //console.log("tempRes : ", tempRes);
        setSearchResult(tempRes);
        enqueueSnackbar(response.data.Items.length + " - active records found in VJToday",{variant:'success'})
        setLoaded(true);
        window.scrollTo(0,0); 
    }).catch((error)=>{
        //console.log(error.message);
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}) 
        setLoaded(true);
        window.scrollTo(0,0); 
    })
}catch(error){
   // setDispMsg(error.message);
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
    setLoaded(true);
    window.scrollTo(0,0); 
}
setLoaded(true);
}



  const toggle = () => {
    //console.log("clicked");
      setCollapsed(!collapsed);
  }

  const handlePDFViewer=async(event, item)=>{
    event.preventDefault();
    //console.log("Item : ", item);
    setSeletedItem(item);
    setLoaded(false);
    try{
      const fetchFile=item.id+".pdf";
      const bucketName='docs.vidyajyoticollege.in/pdfs/vjtoday';
      await axios.get(`${config.api.invokeURL}/presigned/static?fileName=${fetchFile}&bucketName=${bucketName}`,{
          headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
          }}, 
      ).then(async(response)=>{
          //console.log("Response : ", response);
          setPdfFile(response.data.downloadURL);
          //setShowPDF(true); 
          setCollapsed(!collapsed);
          setLoaded(true);
      }).catch((error)=>{
          //console.log("Error1 : ", error);
          setLoaded(true);
      })  
      
    }catch(error){
      //console.log("Error2 : ", error);
      setLoaded(true);
  }
  }

  return (
  <>
      <Helmet>
      <title>Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/publications" />
    </Helmet>

    <Preloader show={loaded ? false : true} />
    {/*<PublicationHeader />
    <FaceView />
    <NewFaceView />*/}
   <Modal  fullscreen isOpen={!collapsed} style={{top:'0%'}}>
    <ModalHeader
      close={<button className='btn bg-white' onClick={toggle}>X</button>}
      toggle={toggle}>
        {selectedItem.issueMonth}  {selectedItem.issueYear} ( {selectedItem.issue} )
        </ModalHeader>
    <ModalBody>
      <PDFViewer pdfFile={pdfFile}/>
    </ModalBody>
    </Modal>
    <div className='container'>
      <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
          <h5 className="text-primary">VJ Today News Letter</h5>
          {/*<h1>The students bring out a monthly publication ‘Ave’ in English which is meditations on the Lectionary readings of each day.</h1>*/}
      </div>
      <div className="row g-5 justify-content-center">
        {searchResult.map((item, index)=>
            <div key={index} className="col-lg-6 col-xl-3 wow fadeIn" data-wow-delay=".3s">
              <div className="card blog-icon btn btn-secondary px-3 rounded-pill" onClick={event=>handlePDFViewer(event, item)}> 
              <a href="#" className="btn text-primary">{item.issueMonth}  {item.issueYear} ( {item.issue} )</a></div>
                {/*<div className="blog-item position-relative bg-light rounded">
                    {/*<img src={item.poster} className="img-fluid w-100 rounded-top" alt="" style={{width:"80px", height:"300px"}} />*/}
                {/*</div>*/}
            </div>)}
        </div>
    </div>
  </>
  )
}

import React, {useEffect, useState} from 'react';
import axios from 'axios';
//import quote from "../assests/images/quote.jpg";
const config = require('../../config.json');

export default function TheologicalInsights() {
    const [insights, setInsights] = useState();
    const [loaded, setLoaded] = useState(false);
    const [imgsrc, setImgsrc] = useState();

    useEffect(() => {
        fetchActiveInfo();
    }, [])

    const generateDate = async () => {
        const current_datetime = new Date();
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        var todayDate="";
        const tDate = todayDate.concat(date_year,"-",month_value,"-", date_value);
        //setUserId(accountNo);
        return tDate;
        //console.log("acc : ", accountNo);
    } 

    const fetchActiveInfo =async()=>{
        try{
           //console.log("Testing"); Welcome to Vidyajyothi College of Theology - Delhi
           //const session = await Auth.currentSession();
           //const access_token=session.idToken.jwtToken;
           setLoaded(false);
            const startDate=await generateDate();
            //console.log("start Date : ", startDate);
            const clientID="VJCollege";
            const searchOption="Theological Insights";
        await axios.get(`${config.api.invokeURL}/dashboard/infodesk?clientID=${clientID}&startDate=${startDate}&searchOption=${searchOption}`,{
            headers: {
             // Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            //console.log(response.data);
            if(response.data.length>0){
                setInsights(response.data[0]);
                if(response.data[0].faithMsg.image){
                    const timgsrc=config.s3.ImageinvokeURL+"images/infodesk/"+response.data[0].faithMsg.image;
                    //console.log("timgsrc : ", timgsrc);
                    setImgsrc(timgsrc); 
                }
                setLoaded(true);

            }
        }).catch((error)=>{
            setLoaded(true);
        })
    }catch(error){
        setLoaded(true);
    }
    }
    
  return (
    <div className='card' style={{color:"#79031d",  overflow:"auto", width:"100%", padding:"5px", border:"3px solid #03AED2", height:"560px"}}>
    <h5 className='p-2' style={{backgroundColor:"#03AED2", borderRadius:"30%", color:"white"    }}>Theological Insights</h5>
        {loaded && imgsrc && <img className='rounded m-2' src={imgsrc} alt='quote' />}
        {loaded && <p  style={{lineHeight:"30px", fontFamily:"Satisfy", fontSize:"22px", textAlign:"justify"}}>
            {insights.faithMsg.message}
       </p>}
    </div>
  )
}

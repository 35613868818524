import React from 'react';
import { Link } from "react-router-dom";

export default function Services() {
  return (
    <div className="container-fluid services py-5 mb-5">
    <div className="container">
        <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
            <h5 className="text-primary">Our Publications</h5>
            <h1 className='text-dark-main'>Publications Built Specifically For Your Learnings</h1>
        </div>
        <div className="row g-5 services-inner">
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
                <div className="services-item bg-light">
                    <div className="p-4 text-center services-content">
                        <div className="services-content-icon">
                            <i className="bi bi-youtube fa-7x mb-4 light-text-combo"></i>
                            <h4 className="mb-3">You Tube Channel</h4>
                            <p className="mb-4">Subscribe to our You Tube Channel to stay connected with our video blogs</p>
                            <Link to={`/publications/youtube`}><a href="#" className="btn bg-dark-main px-5 py-3 rounded-pill">Read More</a></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
                <div className="services-item bg-light">
                    <div className="p-4 text-center services-content">
                        <div className="services-content-icon">
                            <i className="bi bi-journals fa-7x mb-4 light-text-combo"></i>
                            <h4 className="mb-3">Vidyajyoti Journal</h4>
                            <p className="mb-4">VIDYAJYOTI JOURNAL seeks to promote Indian ways of Christian witnessing and theologizing.</p>
                            <Link to={`/publications/vj-journal`}><a href="#" className="btn bg-dark-main px-5 py-3 rounded-pill">Read More</a></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
                <div className="services-item bg-light">
                    <div className="p-4 text-center services-content">
                        <div className="services-content-icon">
                            <i className="bi bi-journals fa-7x mb-4 light-text-combo"></i>
                            <h4 className="mb-3">AVE Reflection</h4>
                            <p className="mb-4">The students bring out a monthly publication ‘Ave’ in English which is meditations on the Lectionary readings of each day.</p>
                            <Link to={`/publications/ace`}><a href="#" className="btn bg-dark-main px-5 py-3 rounded-pill">Read More</a></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
                <div className="services-item bg-light">
                    <div className="p-4 text-center services-content">
                        <div className="services-content-icon">
                            <i className="bi bi-journals fa-7x mb-4 light-text-combo"></i>
                            <h4 className="mb-3">VJ Today</h4>
                            <p className="mb-4">VJ Today New's Letter</p>
                            <Link to={`/publications/vjtoday`}><a href="#" className="btn bg-dark-main px-5 py-3 rounded-pill">Read More</a></Link>
                        </div>
                        
                    </div>
                </div>
            </div>
            {/*<div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                <div className="services-item bg-light">
                    <div className="p-4 text-center services-content">
                        <div className="services-content-icon">
                            <i className="fa fa-envelope-open fa-7x mb-4 light-text-combo"></i>
                            <h4 className="mb-3">Tatavivaveka</h4>
                            <Link to={`/publications/tatavivaveka`}><a href="#" className="btn bg-dark-main px-5 py-3 rounded-pill">Read More</a></Link>
                        </div>
                    </div>
                </div>
            </div>*/}
            {/*<div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                <div className="services-item bg-light">
                    <div className="p-4 text-center services-content">
                        <div className="services-content-icon">
                            <i className="fas fa-laptop fa-7x mb-4 light-text-combo"></i>
                            <h4 className="mb-3">Programming</h4>
                            <p className="mb-4">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt.</p>
                            <a href="" className="btn bg-dark-main text-white px-5 py-3 rounded-pill">Read More</a>
                        </div>
                    </div>
                </div>
            </div>*/}
        </div>
    </div>
</div>
  )
}

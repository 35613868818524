import React from 'react';
import { Link } from "react-router-dom";


export default function Footer() {
  return (
    <div className="gradient-bg-dark-main container-fluid footer wow fadeIn text-decoration-none" data-wow-delay=".3s" >
    <div className="container pt-5 pb-4">
        <div className="row g-5">
            <div className="col-lg-3 col-md-6 short-link">
                <a href="https://vidyajyoticollege.in/">
                    <h1 className="text-white fw-bold d-block text-decoration-none">Vidyajyoti <span className="text-light"> College</span> </h1>
                </a>
                <p className="mt-4 text-light">The Vidyajyoti B.Th. Syllabus is envisaged in three tiers corresponding to the three years of the study of Theology at Vidyajyoti.</p>
                <div className="d-flex hightech-link">
                    <a href="" className="Ylight-bg btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-facebook-f text-dark-main"></i></a>
                    <a href="" className="Ylight-bg btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-twitter text-dark-main"></i></a>
                    <a href="" className="Ylight-bg btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-instagram text-dark-main"></i></a>
                    <a href="" className="Ylight-bg btn-light nav-fill btn btn-square rounded-circle me-0"><i className="fab fa-linkedin-in text-dark-main"></i></a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <a href="#" className="h3 Ylight-text-combo">Short Link</a>
                <div className="mt-4 d-flex flex-column short-link">
                <Link to={`/about/vision-mission`}><a href="" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>About us</a></Link>
                <Link to={`/contact`}><a href="" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>Contact us</a></Link>
                <Link to={`/publications/youtube`}><a href="" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>Our Publications</a></Link>
                <Link to={`/gallery/photo`}><a href="" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>Gallery</a></Link>
                <Link to={`/library/about`}><a href="" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>Library</a></Link>
                <Link to={`/privacy-policy`}><a href="" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>Privacy Policy</a></Link>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <a href="#" className="h3 Ylight-text-combo">External Links</a>
                <div className="mt-4 d-flex flex-column short-link">
                <a href="https://vidyajyotijournal.com" target="_blank" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>VJTR</a>
                <a href="https://www.vjdepth.in/" target="_blank" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>VJ Depth</a>
                <a href="https://jcsaweb.org"target="_blank" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>JERES</a>
                <a href="https://iaju.org" target="_blank" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>IAJU</a>
                    {/*<a href="" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>Terms Of use</a>*/}
                    {/*<a href="" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>Helps</a>
                    <a href="" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>FQAs</a>
                    <a href="" className="mb-2 text-white"><i className="fas fa-angle-right Ylight-text-combo me-2"></i>Contact</a>*/}
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <a href="#" className="h3 Ylight-text-combo">Contact Us</a>
                <div className="text-white mt-4 d-flex flex-column contact-link">
                    <a href="#" className="pb-3 text-light border-bottom border-primary"><i className="fas fa-map-marker-alt Ylight-text-combo me-2"></i> 4-A, Raj Niwas Marg, Delhi – 110054</a>
                    <a href="#" className="py-3 text-light border-bottom border-primary"><i className="fas fa-phone-alt Ylight-text-combo me-2"></i> (+91) 88006 52710</a>
                    <a href="#" className="py-3 text-light border-bottom border-primary"><i className="fas fa-envelope Ylight-text-combo me-2"></i> principalvj@gmail.com</a>
                </div>
            </div>
        </div>
        <hr className="text-light mt-5 mb-4"/>
        <div className="row">
            <div className="col-md-6 text-center text-md-start short-link">
                <span className="text-light"><a href="https://idntica.com" className="text-light"><i className="fas fa-copyright text-light me-2"></i>Idntica.com</a>, All right reserved.</span>
            </div>
            <div className="col-md-6 text-center text-md-end short-link">
                <span className="text-light">Designed By <a href="https://idntica.com" className="text-light"> Idntica</a> {/*Distributed By <a href="https://themewagon.com">ThemeWagon</a>*/}</span>
            </div>
        </div>
    </div>
</div>
  )
}

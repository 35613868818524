import React from 'react'
import {Helmet} from "react-helmet-async";
import {Row, Col} from 'reactstrap';
import lib from '../../assets/images/library/library.png';
import lib2 from '../../assets/images/library/lib2.png'
import lib3 from '../../assets/images/library/lib3.png'

export default function AboutLibrary() {
  return (
    <>
    <Helmet>
      <title>Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/library/about" />
    </Helmet>

    <div className='sec container'>
        <h1 className="mb-3" style={{textAlign:"center"}}>About the Library</h1>
        <div className='d-flex justify-content-between'>
            <Row>
            <Col md={6}>
            <p style={{color:"black", textAlign:'left', marginTop:"60px"}}>The Vidyajyoti library, with about 1,34,000 books and 228 journals, is open to and utilised not only by the Vidyajyoti staff and students, but also by some students, research scholars and staff of Jawaharlal Nehru University, the University of Delhi, Jamia Millia and other scholars from India and abroad.</p>        
                </Col>
                <Col md={6}>
                <img className='img-fluid rounded' src={lib3} alt='vjlibrary' />
                </Col>
            </Row>
        </div>
        <Row>
        <Col md={6}>
        <h5 className="text-primary">Terms and conditions for the external students</h5>
        <p>External students desiring to use Vidyajyoti College Library need to follow the following guidelines</p>
        <div style={{color:"black", textAlign:'left', lineHeight:'30px'}}>
        <ul className="checklist " style={{listStyle:"none"}}>
            <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>A letter from the guide (on the official letterhead of the university) requesting the access to the library needs to be submitted.</li>
            <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>A photocopy of the student’s identity card of the university, a photocopy of the Aadhar card, as well as a residence proof and the contact details are to be submitted.</li>
            <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>A personal declaration stating (a) the purpose of using the library, (b) the assurance that the library usage is strictly for the academic purpose and (c) the promise to submit the bibliographic details of the subsequent individual works published using our library resources has to be submitted.</li>
            <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>External students are not allowed to borrow the books from the library and take them outside the library.</li>
            <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>External students can borrow the books on a daily basis to be used in the reference room. At the library closing time, they will return them on the counter. In case a book is lost, the cost will have to be paid.</li>
            <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>Access to the archival material is strictly prohibited.</li>
            <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>External students will be charged a monthly fees of Rs. 500/- or an annual fees of Rs. 5000/-.</li>
        </ul>
        </div>
        </Col>  
        <Col md={6}>
        <img className='img-fluid rounded' src={lib2} alt='vjlibrary' />
        <img className='img-fluid rounded' src={lib} alt='vjlibrary' />
        </Col>  
        </Row>
       
        <h5 className="text-primary">Contact Information</h5>
        <div className="contact-detail position-relative p-5">
            <div className="row g-5 mb-5 justify-content-center">
            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                    <div className="d-flex bg-light p-3 rounded">
                        <div className="flex-shrink-0 btn-square  Ylight-bg rounded-circle" style={{width: "64px", height: "64px"}}>
                            <i className="fa fa-phone text-dark-main"></i>
                        </div>
                        <div className="ms-3">
                            <h4 className="text-primary">Sudeep Panna</h4>
                            <a className="h5" href="tel:+0123456789">(+91) 92050 10207</a>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                    <div className="d-flex bg-light p-3 rounded">
                        <div className="flex-shrink-0 btn-square Ylight-bg rounded-circle" style={{width: "64px", height: "64px"}}>
                            <i className="fa fa-phone text-dark-main"></i>
                        </div>
                        <div className="ms-3">
                            <h4 className="text-primary">Rohit Varghese</h4>
                            <a className="h5" href="tel:+0123456789">(+91) 98996 26793</a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                    <div className="d-flex bg-light p-3 rounded">
                        <div className="flex-shrink-0 btn-square Ylight-bg rounded-circle" style={{width: "64px", height: "64px"}}>
                            <i className="fa fa-envelope text-dark-main"></i>
                        </div>
                        <div className="ms-3">
                            <h4 className="text-primary">Librarian</h4>
                            <a className="h5" href="mailto:vidyajyotilibrary@gmail.com">vidyajyotilibrary@gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

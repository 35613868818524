import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import './Latest.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import UpComingView from './upcomingModal/UpComingView';
import './upcomingModal//UpcomingModal.css';
import "bootstrap/dist/css/bootstrap.min.css";
import logo from '../../../assets/logo/VJ-College-logo.png';

const config = require('../../../config.json');
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const imgProps={
    0: {
        items: 1,
    },
    1024: {
        items: 2,
        itemsFit: 'contain',
    }
  };


export default function LatestCalendar() {
    const carouselRef = useRef(null);
    const [insights, setInsights] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [show, setShow] = useState(false);
    const [eventDetails, setEventDetails] = useState({topStory:{}});

    useEffect(() => {
        fetchActiveInfo();
      }, []);

      const generateDate = async () => {
        const current_datetime = new Date();
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        var todayDate="";
        const tDate = todayDate.concat(date_year,"-",month_value,"-", date_value);
        //setUserId(accountNo);
        return tDate;
        //console.log("acc : ", accountNo);
    } 


    const fetchActiveInfo =async()=>{
        try{
           setLoaded(false);
            const startDate=await generateDate();
            const clientID="VJCollege";
            const searchOption="UpComing Events";
        await axios.get(`${config.api.invokeURL}/dashboard/infodesk?clientID=${clientID}&startDate=${startDate}&searchOption=${searchOption}`,{
            headers: {
             // Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            if(response.data.length>0){
                const test=response.data;
                //const result=test.sort((a,b) => (a.updatedOn > b.updatedOn) ? 1 : ((b.updatedOn > a.updatedOn) ? -1 : 0))
                const result=test.sort((a,b) => new Date(a.startDate) - new Date(b.startDate) ); // b - a for reverse sort
                //console.log(result);
                setInsights(result);
                setLoaded(true);
            }
        }).catch((error)=>{
            setLoaded(true);
        })
    }catch(error){
        setLoaded(true);
    }
    }

    const handleClose = () => setShow(false);

    const handleShow = (e,item) => {
        e.preventDefault();
        //console.log("item :", item);
        setEventDetails(item);
        setShow(true);  
    }


  return (
    <div className='' style={{color:"#79031d",  overflow:"auto", width:"100%", padding:"2px", height:"560px"}}>
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Body>
                <UpComingView eventDetails={eventDetails} handleClose={handleClose}/>
            </Modal.Body>
        </Modal>
        <div className="container">
    <div className="row text-center">
        <h3>Up Coming Events</h3>
    </div>
    <div className="row">
        <div className="col-md-12">
            <div id="news-slider" ref={carouselRef} className="owl-carousel owl-theme" style={{opacity: "1", display: "block"}}>
                {insights.length>0 ? <AliceCarousel mouseTracking                    
                    autoPlay infinite 
                    responsive={imgProps}
                    animationDuration={3000}
                    animationType="fadeout"
                    autoPlayDirection="ltr"
                    disableButtonsControls={true}
                    autoHeight={true}
                    disableDotsControls={true}>
                {loaded && insights.map((event, index)=>
                <div className="owl-item card" key={index}>
                    <div className="post-slide">
                        <div className="post-date author-img">
                            <span className="date">{new Date(event.startDate).getDate()}</span>
                            <span className="month">{months[new Date(event.startDate).getMonth()]}</span>
                        </div>
                        <div className="post-content">
                            <img className="img_style" src={`${config.s3.ImageinvokeURL}${"images/upcomingevents/"}${event.topStory.timelineImage}`} alt={event.topStory.timelineTitle} />
    
                            <h3 className="post-title">
                                <a href="#">{event.topStory.timelineTitle}</a>
                            </h3>
    
                            <p className="post-description truncate-3-lines">
                            {event.topStory.timelineDescription}                            </p>
                            <a href="#" className="read-more" onClick={e=>handleShow(e, event)}>read more</a>
                        </div>
                    </div>
                </div>
                )}
    </AliceCarousel>:
    <div><img src={logo} width="400px" /></div>}
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

import React from 'react';
import './UpcomingModal.css';
import logo from '../../../../assets/logo/VJ-College-logo.png';

const config = require('../../../../config.json');

export default function UpComingView(props) {
  const {eventDetails, handleClose}=props;
  return (
    <div>
                <div className="" role="document">
                  <div className="modal-content">
                    <div className="modal-body row">
                      <div className="content col-sm-6">
                        <h3 className="title red-title">{eventDetails.topStory.timelineHeader}</h3>
                        <span className="sub-title">{eventDetails.topStory.timelineTitle}</span>
                        <p className="description">{eventDetails.topStory.timelineDescription}</p>
                      </div>
                      <div className="col-sm-6 modal-image">
                      {eventDetails.topStory.timelineImage ? <div className="title"><img src={`${config.s3.ImageinvokeURL}${"images/upcomingevents/"}${eventDetails.topStory.timelineImage}`} alt={eventDetails.topStory.timelineTitle} width="340px" height="340px"/></div>:
                       <div className="title"><img src={logo} alt={eventDetails.topStory.timelineTitle} width="340px" height="340px"/></div>}
                      </div>

                    </div>
                    <div className="text-center center">
                    <button className="btn text-center center" onClick={handleClose}>Close</button>
                    </div>
                  </div>
                </div> 

    </div>
  )
}

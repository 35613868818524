import React from 'react'

export default function TopbarView() {
  return (
    <div className="topbar container-fluid">
    <div >
        <div className="d-flex justify-content-around ">
            <div className=" top-info">
                <small className="me-3"><i className=" fas fa-map-marker-alt me-2 "></i>4-A, Raj Niwas Marg, Delhi – 110054</small>
                <small className="me-3"><i className="fas fa-envelope me-2 "></i>principalvj@gmail.com</small>
                <small className="me-3"><i className="fas fa-phone-alt me-2 "></i>(+91) 99711 86877</small>
            </div>
          
           {/*} <div className="top-link">
                <a href="https://www.facebook.com/VidyajyotiCollegeoftheology/" target="_blank" rel="noreferrer" className="bg-dark-main nav-fill btn btn-sm-square rounded-circle" ><i className="fab fa-facebook-f text-white"></i></a>
                <a href="https://www.youtube.com/channel/UCdNmmekVJMKCbO2lISZNCWw" target="_blank" rel="noreferrer" className="bg-dark-main nav-fill btn btn-sm-square rounded-circle" ><i className="fab fa-youtube text-white"></i></a>
                {/*<a href="" className="bg-dark-main nav-fill btn btn-sm-square rounded-circle"><i className="fab fa-instagram text-white"></i></a>
                <a href="" className="bg-dark-main nav-fill btn btn-sm-square rounded-circle me-0"><i className="fab fa-linkedin-in text-white"></i></a>
            </div>*/}
        </div>
    </div>
    </div>
  )
}

import React from 'react';
import {Helmet} from "react-helmet-async";


export default function MethodologyContent() {
  return (
 
    <div className='sec'>
    <Helmet>
      <title>CONTEXTUAL SYSTEMATIC THEOLOGY - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="CONTEXTUAL SYSTEMATIC THEOLOGY - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="CONTEXTUAL SYSTEMATIC THEOLOGY - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/academics/methodolgoy" />
    </Helmet>
    <div className='container'>
        <h1 className="mb-3" style={{textAlign:"center"}}>LICENTIATE IN SACRED THEOLOGY (STL) in CONTEXTUAL SYSTEMATIC THEOLOGY</h1>
        <p style={{color:"black", textAlign:'justify'}}>The second cycle which leads to the Licentiate Degree (S.T.L) consists of a specialized study of a particular area of theology and an advanced initiation into the methods of scholarly study and research. Besides the courses and seminars, this degree demands two major papers and a dissertation.</p>
        <p style={{color:"black", textAlign:'justify'}}>The areas of specialization of particular concern to India and to be offered in this Faculty are Systematic and Indian Christian Theology, as specified below.</p>
        <p style={{color:"black", textAlign:'justify'}}>The Licentiate degree requires four semesters of study after the completion of the B.Th. degree. The students are expected to complete the Licentiate within two years. Exception will be considered only in rare cases.</p>
        <p style={{color:"black", textAlign:'justify'}}>Students who have obtained the B.Th. from Vidyajyoti College of Theology and have an average of at least 55% (High Second Class) marks in their overall performance of the six semesters may be admitted to the Licentiate.</p>
        <p style={{color:"black", textAlign:'justify'}}>The requirements for admission to the second cycle for students who have no degree in Theology but have completed the six years of the ecclesiastical studies in Philosophy and Theology will be decided by the Executive Council or the Admission Board.</p>
    </div>
    </div>
  )
}

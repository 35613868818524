import React from 'react';
import {Row, Col} from 'reactstrap';
import { Link } from 'react-router-dom';


export default function NewFaceView() {

  return (
    <div >
    <div >
        <div className="faceview position-relative">
            <div className=" row g-2" >
               
                <div className="col-xl-3 col-lg-6 wow " data-wow-delay=".4s">
               <Link to="/about/vision-mission"><div className=" Ylight-bg p-2 rounded " style={{height:"100px", cursor: "pointer"}}>
                    <Row className='p-3'>
                    <Col xs={2}>
                    <div className="flex-shrink-0 btn-square  bg-dark-main rounded-circle" style={{width: "50px", height: "50px" }}>
                            <i className="fas fa-cross text-white"></i>
                        </div>
                    </Col>
                    <Col>
                   <div className="d-flex ms-3 text-center justify-content-center">
                            <h6 className="text-primary  pt-2" >Contextual-Inclusive Theology</h6>
                            {/*<p>Defend Your Faith</p>*/}
                        </div>
                    </Col>
                 </Row>   
                    </div></Link>
                </div>
               <div className="col-xl-3 col-lg-6 wow " data-wow-delay=".4s">
               <Link to="/about/vision-mission"><div className=" Ylight-bg p-2 rounded " style={{height:"100px", cursor: "pointer"}}>
                    <Row className='p-3'>
                    <Col xs={2}>
                    <div className="flex-shrink-0 btn-square  bg-dark-main rounded-circle" style={{width: "50px", height: "50px" }}>
                            <i className="fas fa-eye text-white"></i>
                        </div>
                    </Col>
                    <Col>
                   <div className="d-flex ms-3 text-center justify-content-center">
                            <h6 className="text-primary  pt-2" >Vision & Misssion</h6>
                            {/*<p>Defend Your Faith</p>*/}
                        </div>
                    </Col>
                 </Row>   
                    </div></Link>
                </div>
                <div className="col-xl-3 col-lg-6 wow" data-wow-delay=".5s">
                <Link to="/about/history"><div className=" Ylight-bg p-2 rounded " style={{height:"100px", cursor: "pointer"}}>
                    <Row className='p-3'>
                    <Col xs={2}>
                    <div className="flex-shrink-0 btn-square  bg-dark-main rounded-circle" style={{width: "50px", height: "50px" }}>
                            <i className="fas fa-share text-white"></i>
                        </div>
                    </Col>
                    <Col>
                    <div className="d-flex ms-3 text-center justify-content-center">
                            <h6 className="text-primary  pt-2" >Our History</h6>
                            {/*<p>Defend Your Faith</p>*/}
                        </div>
                    </Col>
                 </Row>  
                    </div></Link>
                </div>
                <div className="col-xl-3 col-lg-6 wow" data-wow-delay=".6s">
                <Link to="/about/formation"><div className=" Ylight-bg p-2 rounded " style={{height:"100px", cursor: "pointer"}}>
                    <Row className='p-3'>
                    <Col xs={2}>
                    <div className="flex-shrink-0 btn-square  bg-dark-main rounded-circle" style={{width: "50px", height: "50px" }}>
                            <i className="fas fa-user-check text-white"></i>
                        </div>
                    </Col>
                    <Col>
                    <div className="d-flex ms-3 text-center justify-content-center">
                            <h6 className="text-primary  pt-2" >Transformative Formation</h6>
                            {/*<p>Defend Your Faith</p>*/}
                        </div>
                    </Col>
                 </Row>
                    </div>
                    </Link>
                </div>
            </div>
           {/*} <h4 className='fade-in-text bg-dark-main' style={{textAlign:"center", lineHeight:"40px", paddingLeft:"100px", paddingRight:"100px", fontStyle:"italic", color:"white"}}>Vidyajyoti is more than just an academic institution. It is a way of life designed to form men and women dedicated to the service of others, who let the inner Divine Light shine out through them.</h4>*/}
        </div>
    </div> 
</div>
  )
}
